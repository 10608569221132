html, body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: left;
  background-color: #ececec;
  padding: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #bf1363;
}

.cardgrid {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr; */
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* Cards in the grid use the item class */
.item {
  /* width: 33%; */
  padding-bottom: 4%; /* Same as width, sets height */
  padding-top: 4%; /* Same as width, sets height */
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: 4%; /* (100-32*3)/2 */
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
}
